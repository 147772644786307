import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/pages/_broker_demo_form.scss'

const marketoScriptId = 'mktoForms'

const BrokerDemoForm = props => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        if (!document.getElementById(marketoScriptId)) {
            loadScript()
        } else {
            setIsLoaded(true)
        }
    }, [])

    const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const detectInterests = () => {
        let interests = props.location.state && props.location.state.interests
        if (!interests) {
            // Check url params
            let interestsRaw = new URLSearchParams(window.location.search).get(
                'interests'
            )
            if (interestsRaw) {
                interests = interestsRaw.split(',').map(capitalizeFirstLetter)
            }
        }

        let interestList = []

        if (interests) {
            interests.forEach(function(interest) {
                interestList.push('HomeSpotter - ' + interest)
            })
        }
        return interestList
    }

    useEffect(() => {
        let interestList = detectInterests()

        isLoaded &&
            window.MktoForms2.loadForm(
                '//get.lwolf.com',
                '803-SUI-561',
                3275,
                function(form) {
                    if (interestList.length) {
                        form.vals({ Product_s_of_Interest__c: interestList })
                    }

                    form.onSuccess(function(values, followUpUrl) {
                        form.getFormElem().hide()
                        setShowSuccess(true)
                        return false
                    })
                }
            )
    }, [isLoaded])

    const loadScript = () => {
        let s = document.createElement('script')
        s.id = marketoScriptId
        s.type = 'text/javascript'
        s.async = true
        s.src = '//get.lwolf.com/js/forms2/js/forms2.min.js'
        s.onreadystatechange = function() {
            if (
                this.readyState === 'complete' ||
                this.readyState === 'loaded'
            ) {
                setIsLoaded(true)
            }
        }
        s.onload = () => setIsLoaded(true)
        document.getElementsByTagName('head')[0].appendChild(s)
    }

    return (
        <>
            <Helmet title={'Brokerage Request a Demo'}>
                <html lang="en" />
            </Helmet>
            <Header isLaunchpad={true} />
            <div className={'broker-marketo-form'}>
                {!showSuccess && <h3>Request a Demo - Brokerage</h3>}
                {showSuccess && (
                    <>
                        <h3>Thank you for contacting us!</h3>
                        <p>We'll be in touch soon.</p>
                    </>
                )}
                <form id={`mktoForm_3275`}></form>
            </div>
            <Footer />
        </>
    )
}

export default BrokerDemoForm
